<template>
  <div class="card">
    <div class="card-header">
      <a-button class="mr-2" :disabled="!$auth.isLoggedIn()">
        <template #icon
          ><i class="fa fa-plus" @click="showModalNewUgps"
        /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length != 1 || !$auth.isLoggedIn()"
        @click="editEntity(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length == 0 || !$auth.isLoggedIn()"
        @click="deleteEntity(selectedRowKeys)"
      >
        <template #icon><i class="fa fa-trash" /></template>
      </a-button>
      <a-upload class="mr-2" :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
      <a-button>
        <upload-outlined></upload-outlined>
        Выберите файл
      </a-button>
    </a-upload>
    <a-button
      type="primary"
      :disabled="fileList.length === 0"
      :loading="uploading"
      style="margin-top: 16px"
      @click="handleUpload"
    >
      {{ uploading ? 'Загружаю' : 'Начать загрузку' }}
    </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="{ x: 'calc(100%)', y: '100%' }"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
    />

    <a-modal
      width="100"
      v-model:visible="visibleUgps"
      title="Наименование"
      @ok="handleOkUgps"
    >
      <a-form v-if="visibleUgps" ref="programForm" :model="UgpsFormState">
        <a-form-item label="Наименование">
          <a-input
            v-model:value="UgpsFormState.UgpsTitle" :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
      
      <a-form-item label="УМО" >
        <a-select v-model:value="UgpsFormState.UgpsUmo" :disabled="!$auth.isLoggedIn()">
          <a-select-option
            v-for="umo in umos"
            :key="umo.id"
            v-bind:value="umo.id"
          >
            {{ umo.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Лаборатория" >
        <a-select v-model:value="UgpsFormState.UgpsLaboratory" :disabled="!$auth.isLoggedIn()">
          <a-select-option
            v-for="laboratory in laboratories"
            :key="laboratory.id"
            v-bind:value="laboratory.id"
          >
            {{ laboratory.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
              <a-form-item
              label="ФГОСы"
              class="table-item start-table-section"
            >
              <div class="card-header">
                <FGOSes
                  v-if="!isNew"
                  :UGPSID="this.UgpsFormState.UgpsID"
                  :scroll="{ y: 220 }"
                />
                <span v-else>Доступно после сохранения</span>
              </div>
            </a-form-item>
            </a-form>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons-vue";
import { ref } from "vue";
import request from "umi-request";
import { message } from "ant-design-vue";
import FGOSes from "@/views/DropDownEntities/Fgoses.vue";

export default {
  name: "Ugps",
  components: {
    UploadOutlined,
    FGOSes
  },
  computed: {    
    isNew() {
    return !this.UgpsFormState.UgpsID;
  },
  },
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID" },
        { dataIndex: "title", title: "Наименование" },
        { dataIndex: "umo.title", title: "УМО" },
        { dataIndex: "laboratory.title", title: "Лаборатория" },
      ],
      gridData: [],
      visibleUgps: false,
      thisUgps: [],
      UgpsFormState: {
        UgpsID: undefined,
        UgpsTitle: undefined,
        UgpsUmo: undefined,
        UgpsLaboratory: undefined,
      },
      selectedRowKeys: [],
      selectedRows: [],
      fileList: [],
      uploading: ref(false),
      laboratories: [],
      umos: [],
    };
  },
  methods: {
    async reload() {
      this.gridData = (await axios.get("ugps")).data;
      this.laboratories = (await axios.get("laboratory")).data;
      this.umos = (await axios.get("umo")).data;
    },
    rowClick(record, index) {
      return {
        onClick: () => {
          console.log(record, index, 1111);
          this.thisUgps = record;
          this.UgpsFormState.UgpsTitle = record.title;
          this.UgpsFormState.UgpsID = record.id;
          if (record.umo != null)
          this.UgpsFormState.UgpsUmo = record.umo.id;
          if (record.laboratory != null)
          this.UgpsFormState.UgpsLaboratory = record.laboratory.id;
          this.showModalUgps();
        },
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    showModalUgps() {
      this.visibleUgps = true;
    },
    showModalNewUgps() {
      this.UgpsFormState.UgpsID = undefined;
      this.UgpsFormState.UgpsTitle = undefined;
      this.UgpsFormState.UgpsUmo = undefined;
      this.UgpsFormState.UgpsLaboratory = undefined;
      this.visibleUgps = true;
    },
    async handleOkUgps() {
      console.log("UgpsModal.");
      this.visibleUgps = false;
      if (this.UgpsFormState.UgpsTitle !== undefined) {
        if (this.UgpsFormState.UgpsID == undefined) {
          try {
            await axios.post("ugps", {
              title: this.UgpsFormState.UgpsTitle,
              umo: this.UgpsFormState.UgpsUmo,
              laboratory: this.UgpsFormState.UgpsLaboratory,
            });
          } finally {
            this.UgpsFormState.UgpsID = undefined;
            this.UgpsFormState.UgpsTitle = undefined;
            this.UgpsFormState.UgpsUmo = undefined;
            this.UgpsFormState.UgpsLaboratory = undefined;
            this.UgpsFormState.UgpsID = undefined;
            await this.reload();
          }
        } else {
          console.log("UgpsModal.");
          console.log({
            id: this.UgpsFormState.UgpsID,
            title: this.UgpsFormState.UgpsTitle,
            umo: this.UgpsFormState.UgpsUmo,
            laboratory: this.UgpsFormState.UgpsLaboratory,
          });
          try {
            await axios.put("ugps", {
              id: this.UgpsFormState.UgpsID,
              title: this.UgpsFormState.UgpsTitle,
              umo: this.UgpsFormState.UgpsUmo,
              laboratory: this.UgpsFormState.UgpsLaboratory,
            });
          } finally {
            this.UgpsFormState.UgpsID = undefined;
            this.UgpsFormState.UgpsTitle = undefined;
            this.UgpsFormState.UgpsUmo = undefined;
            this.UgpsFormState.UgpsLaboratory = undefined;
            this.UgpsFormState.UgpsID = undefined;
            await this.reload();
          }
        }
      } else {
        return;
      }
    },
    async deleteEntity(selectedRowKeys) {
      console.log(selectedRowKeys);
      try {
        await axios.delete("ugps", {
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: selectedRowKeys,
        });
      } finally {
        selectedRowKeys = undefined;
        await this.reload();
      }
    },
    async editEntity(selectedRows) {
      console.log(selectedRows);
      this.UgpsFormState.UgpsID = selectedRows[0].id;
      this.UgpsFormState.UgpsTitle = selectedRows[0].title;
      this.UgpsFormState.UgpsUmo = selectedRows[0].umo;
      this.UgpsFormState.UgpsLaboratory = selectedRows[0].laboratory;
      this.showModalUgps();
    },
      handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  },
  beforeUpload(file) {
    this.fileList.push(file);
    return false;
  },
  async handleUpload() {
    const formData = new FormData();
    this.fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    this.uploading = true;
    request(axios.defaults.baseURL + "/Upload/Ugps", {
      method: "post",
      data: formData,
    })
      .then(() => {
        this.fileList = [];
        this.uploading = false;
        message.success("Загрузка прошла успешно.");
        this.reload();
      })
      .catch(() => {
        this.uploading = false;
        message.error("Ошибка Загрузки.");
      });
  },
  },
  async created() {
    await this.reload();
  },
};
</script>
